<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'verifySlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title">
                  Transfer Slip - {{ details.transfer_id }}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div
                  class="bill-head-icon mr-1"
                  @click="closeSlipModal()"
                  title="Close Slip"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center" v-if="slipSettings.organization">
              <p class="font-xxxl mb-0">{{ slipSettings.organization.name }}</p>
              <p class="mb-0 font-print-xxs">
                {{ details.branch_from.address }}
                <br />
                Tel No:
                {{ details.branch_from.phone }} /
                {{ details.branch_from.email }}
                <br />
                PAN: {{ slipSettings.organization.pan_vat }}
              </p>
              <hr class="print-hr-margin" />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Transfer To</label>
                <!--    <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6> -->
                <p class="mg-b-0">NAME : {{ details.branch_to.name }}</p>
                <p class="mg-b-0">ADDRESS : {{ details.branch_to.address }}</p>
              </div>
              <!-- col -->
              <div class="col-sm-6 col-lg-4">
                <p class="mg-b-0 text-right mt-sm-3">
                  Transfer ID : {{ details.transfer_id }}
                </p>
                <p class="mg-b-0 text-right">
                  Transfer DATE : {{ details.date }}
                </p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            <div class="mt-2">
              <table class="table table3 font-print-xxs">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N</th>
                    <th scope class="wd-55p">Particular</th>
                    <th scope class="wd-15p">Qty</th>
                    <th scope class="wd-10p">Rate</th>
                    <th scope class="wd-15p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                  <tr v-for="(item, index) in details.items" :key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>{{ item.item.name }}</td>
                    <td>{{ item.quantity }} {{ item.item.unit_type.title }}</td>
                    <td>{{ parseDigitForSlip(item.actual_purchase_rate) }}</td>
                    <td class="tx-right">
                      {{ parseDigitForSlip(item.total) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="
                  col-sm-7 col-lg-7
                  print-sm-12 print-lg-4
                  order-2 order-sm-0
                  mg-t-5 mg-sm-t-0
                "
              >
                <!-- Shipping-detail -->
                <div
                  class="footer_detail mb-0 p-2"
                  v-if="details.vehicle || details.driver || details.contact"
                >
                  <label class="content-label mb-1">Shipping Detail</label>
                  <p class="mg-b-0">Vehicle No : {{ details.vehicle }}</p>
                  <p class="mg-b-0">Driver Name : {{ details.driver }}</p>
                  <p class="mg-b-0">Contact No : {{ details.contact }}</p>
                </div>
                <div
                  class="footer_detail mt-2 mb-0 p-2"
                  v-if="
                    details.responsible_person ||
                    details.responsible_person_contact
                  "
                >
                  <label class="content-label mb-1">Responsible Person</label>
                  <p class="mg-b-0">Name : {{ details.responsible_person }}</p>
                  <p class="mg-b-0">
                    Contact No.: {{ details.responsible_person_contact }}
                  </p>
                </div>
                <!-- Shipping-detail -->
              </div>

              <!-- col -->
              <div
                class="
                  col-sm-4 col-lg-4
                  print-sm-12 print-lg-4
                  order-1 order-sm-0
                  color-secondary
                  mb-0
                  mt-0
                  font-print-xxs
                "
              >
                <ul class="list-unstyled lh-6 mb-0 print-sm-mg">
                  <li class="d-flex justify-content-between">
                    <span>Total Amount</span>
                    <span>{{ details.total }}</span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            <hr />
            <!-- Footer Container -->
            <div class="print-mb-3">
              <div class="row">
                <div class="col-sm-8">
                  <div class="footer_note" v-if="details.note">
                    <label class="content-label mb-1 no-print-sm">Note</label>
                    <p class="mb-0 text-center text-sm-left font-print-xxs">
                      {{ details.note }}
                    </p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <p class="mb-0 mt-p-2 text-right font-print-xxs">
                    <span>Transfer User: {{ details.user_detail.name }}</span>
                  </p>
                  <p
                    class="mb-0 mt-p-2 text-right font-print-xxs"
                    v-if="details.verification"
                  >
                    <span>Verified By: {{ details.verification.name }}</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- Verify Section -->
            <div class="col-md-12 bg-theam-secondary p-2 mt-2">
              <div class="row">
                <div class="col-md-9">
                  <div class="page-text">Verify Note</div>
                  <textarea
                    name="note"
                    class="form-control"
                    v-model="formData.verify_note"
                  ></textarea>
                </div>
                <div class="col-md-3 text-right pd-t-20">
                  <button
                    @click="verify"
                    type="button"
                    class="btn btn-success btn-sm btn-min-wd"
                    :disabled="verifying"
                  >
                    <span v-if="verifying">Verifying...</span>
                    <span v-else>Verify</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
      loggedInUserName: "",
      formData: {
        verify_note: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters(["dataLists", "dataId", "modalId", "modalMode"]),
    ...mapGetters("stockTransfer", [
      "verifyId",
      "dataSets",
      "slipSettings",
      "verifying",
    ]),
  },
  mounted() {
    //what this does actaully is .. it makes no need to refresh manage return page to show the canceled user name.
    let user = JSON.parse(localStorage.getItem("authData"));
    this.loggedInUserName = user.user.name;
  },
  methods: {
    verify() {
      this.$store.commit("stockTransfer/setVerifying", true);
      this.$emit("verifyStock", this.formData);
    },
    print() {
      window.print();
    },
    closeSlipModal() {
      this.$store.dispatch("modalClose");
    },
  },
  watch: {
    verifyId(value) {
      let that = this;
      this.details = this.dataSets.find(function (data) {
        if (data.id == value) {
          that.formData.verify_note = data.verify_note;
          that.formData.id = value;
          return data;
        }
      });
    },
  },
};
</script>